import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ContactForm from '../components/ContactForm';

function Contact() {
    return (
        <section id="contact">
          <Container className='Contact-container'>
            <Row className='Contact-content'>
              <Col xs={12} lg={6} className='Contact-logo'>
                <img src={`${process.env.PUBLIC_URL}/images/Glifo.png`} className="logo" alt="Logo" />
              </Col>
              <Col xs={12} lg={6} className='Contact-form'>
                <h2>Contattami!</h2>
                <p>Compila il form qui sotto</p>
                <ContactForm />
                <div className='Contact-mail'>
                  <p>oppure scrivi una mail a:
                    <a href='mailto:info@francescatronci.it'>info@francescatronci.it</a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      );
};

export default Contact;
