import React, { useState, useEffect } from 'react';

function Navbar() {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let currentSection = '';

      sections.forEach(section => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (window.scrollY >= sectionTop - 60 && window.scrollY < sectionTop + sectionHeight - 60) {
          currentSection = section.getAttribute('id');
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTopAndClearUrl = (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    const welcomeSection = document.getElementById('welcome');
    if (welcomeSection) {
      welcomeSection.scrollIntoView({ behavior: 'smooth' });
      window.history.pushState(null, '', window.location.pathname); // Clear the URL
    }
  };

  return (
    <nav className='NavBar'>
      <ul>
        <li>
            <a href="#welcome" onClick={scrollToTopAndClearUrl}>
                <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} className="logo" alt="Logo" />
            </a>
        </li>
        <li>
            <a href="#about" className={activeSection === 'about' ? 'active' : ''}>Qualcosa su di me</a>
        </li>
        <li>
            <a href="#study" className={activeSection === 'study' ? 'active' : ''}>Gli studi e le passioni</a>
        </li>
        <li>
            <a href="#skills" className={activeSection === 'skills' ? 'active' : ''}>Le mie competenze</a>
        </li>
        <li>
            <a href="#experiences" className={activeSection === 'experiences' ? 'active' : ''}>Le mie esperienze</a>
        </li>
        <li>
            <a href="#contact" className={activeSection === 'contact' ? 'active' : ''}>Contattami</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
