import React from 'react';


function Chip({ title }) {
    return (
        <div className='chip-container'>
            <div className='chip-content'>
                <p className='chip-title'>{title}</p>
            </div>
        </div>
    );
}

export default Chip;