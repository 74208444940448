import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../components/Card';

function Study() {
    return (
        <section id="study">
            <Container className='Study-container'>
                <Row className='Study-content'>
                    <Col xs={12} lg={6} className='Study-logo'>
                        <img src={`${process.env.PUBLIC_URL}/images/Glifo.png`} className="logo" alt="Logo" />
                    </Col>
                    <Col xs={12} lg={6} className='Study-description'>
                        <h2>Gli studi...</h2>
                        <Card
                            title="2018: Communication Design"
                            description="Qualifica di tecnico della programmazione e dello sviluppo di siti internet e pagine web"
                        />
                        <Card
                            title="2005 - 2011: Perito Ragioniere Commerciale e Programmatore Informatico"
                            description="Diploma di scuola media superiore"
                        />
                        <h2>...le passioni</h2>
                        <Card
                            title="LIS - Lingua dei Segni Italiana"
                            description="Ho frequentato con passione e interesse questo corso triennale per imparare la Lingua dei Segni Italiana. Un'esperienza che consiglio a tutti!"
                        />
                        <Card
                            title="Violinista"
                            description="Nel tempo libero mi diletto a suonare il mio violino, un sogno nel cassetto che avevo fin da bambina"
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Study;