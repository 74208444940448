import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../components/Card';

function Experience() {
    return (
        <section id="experiences">
            <Container className='Experience-container'>
                <Row className='Experience-content'>
                    <Col xs={12} lg={6} className='Experience-logo'>
                        <img src={`${process.env.PUBLIC_URL}/images/Glifo.png`} className="logo" alt="Logo" />
                    </Col>
                    <Col xs={12} lg={6} className='Experience-description'>
                        <h2>Le mie esperienze</h2>
                        <Card
                            title="2018 - Attuale: FrontEnd Developer e UI/UX Designer"
                            address="ExtraRed srl - Pontedera (PI)"
                            description="Sviluppo di interfacce grafiche ed esperienza utente di siti web portali, applicazioni web e applicazioni iOS e Android. Sviluppo pagine HTML FrontEnd con utilizzo di librerie JavaScript come JQuery e framework di stile come Bootstrap 3 e 4. Utilizzo del CMS Liferay 7.3, 7.4, in particolare creazioni di temi (tramite utilizzo di linguaggi HTML e SCSS) e contenuti web gestiti da strutture e template con linguaggio Freemarker."
                        />
                        <Card
                            title="2018: Sviluppatrice Web"
                            address="Mentarossa Comunicazione - Pisa (PI)"
                            description="Sviluppatore Web presso l'azienda. Installazione, personalizzazione e manutenzione siti con WordPres. Creazione e manutenzione di Landing Page in HTML, CSS, PHP."
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Experience;