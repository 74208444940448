import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Welcome() {
    return (
        <section id="welcome">
            <Container className='Welcome-container'>
                <Row className='Welcome-content'>
                    <Col xs={12} lg={6} className='Welcome-logo'>
                        <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} className="logo" alt="Logo" />
                    </Col>
                    <Col xs={12} lg={6} className='Welcome-title'>
                        <h1>Francesca Tronci</h1>
                        <h2>Web & Graphic Designer</h2>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Welcome;