import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Chip from '../components/Chip';

function Skill() {
    return (
        <section id="skills">
            <Container className='Skill-container'>
                <Row className='Skill-content'>
                    <Col xs={12} lg={6} className='Skill-logo'>
                        <img src={`${process.env.PUBLIC_URL}/images/Glifo.png`} className="logo" alt="Logo" />
                    </Col>
                    <Col xs={12} lg={6} className='Skill-description'>
                        <Row>
                            <h2>Le competenze acquisite</h2>
                            <Col xs={6} lg={6} className='Skill-web-description'>
                                <h3>Web Design</h3>
                                <Chip 
                                    title="HTML"
                                />
                                <Chip 
                                    title="CSS/SCSS"
                                />
                                <Chip 
                                    title="Freemarker"
                                />
                                <Chip 
                                    title="React"
                                />
                                <Chip 
                                    title="JavaScript"
                                />
                                <Chip 
                                    title="PHP"
                                />
                                <Chip 
                                    title="Liferay"
                                />
                                <Chip 
                                    title="WordPress"
                                />
                            </Col>
                            <Col xs={6} lg={6} className='Skill-graphic-description'>
                                <h3>Graphic Design</h3>
                                <Chip 
                                    title="Figma"
                                />
                                <Chip 
                                    title="Illustrator"
                                />
                                <Chip 
                                    title="Photoshop"
                                />
                                <Chip 
                                    title="InDesign"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Skill;