import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function About() {
    return (
        <section id="about">
          <Container className='About-container'>
            <Row className='About-content'>
              <Col xs={12} lg={6} className='About-description'>
                <h2>Benvenuti!</h2>
                <p>
                  Sono Francesca,<br />
                  una <strong>web designer e sviluppatrice</strong> specializzata in <strong>UI/UX</strong>, nata sotto il sole radioso di Pisa.
                  Il mio carattere solare ed estroverso riflette le mie origini toscane e infonde energia in ogni progetto che affronto.
                  <br /><br />
                  La mia avventura nel mondo della grafica e del web design è iniziata ufficialmente nel 2017, ma la mia passione per il coding risale agli anni del liceo, quando già sperimentavo con HTML e CSS.
                  Ho conseguito il <strong>diploma di ragioniere commerciale e programmatore informatico</strong> e dopo anni di esperienza lavorativa ho deciso di <strong>riprendere gli studi nel campo informatico come autodidatta</strong>.
                  <br /><br />
                  La svolta è arrivata con il conseguimento di un <strong>secondo diploma in Communication Design</strong>, che ha perfezionato le mie abilità sia nello sviluppo del codice che nella grafica web.
                  Questa formazione completa mi ha aperto le porte del settore IT, permettendomi di emergere come professionista versatile e creativa, con una particolare attenzione all'User Interface (UI) e User Experience (UX).
                  <br /><br />
                  Oggi <strong>unisco competenze tecniche, visione artistica e una profonda comprensione delle esigenze degli utenti</strong> per creare esperienze digitali non solo esteticamente <strong>accattivanti</strong>, ma anche <strong>intuitive</strong> e <strong>funzionali</strong>.
                  <br /><br />
                  La mia missione è <strong>trasformare le vostre idee</strong> in modo che <strong>soddisfino e superino</strong> le aspettative degli utenti, combinando <strong>design innovativo e usabilità ottimale</strong>.
                </p>              
              </Col>
              <Col xs={12} lg={6} className='About-photo'>
                <img src={`${process.env.PUBLIC_URL}/images/francesca.jpeg`} className="Personal-photo" alt="Logo" />
              </Col>
            </Row>
          </Container>
        </section>
      );
};

export default About;
