import React, { useState, useEffect } from 'react';

function NavMobile() {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let currentSection = '';

      sections.forEach(section => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (window.scrollY >= sectionTop - 60 && window.scrollY < sectionTop + sectionHeight - 60) {
          currentSection = section.getAttribute('id');
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="bottom-navigation">
      <ul>
        <li>
          <a href="#about" className={activeSection === 'about' ? 'active' : ''}>Chi Sono</a>
        </li>
        <li>
          <a href="#study" className={activeSection === 'study' ? 'active' : ''}>Studi</a>
        </li>
        <li>
          <a href="#skills" className={activeSection === 'skills' ? 'active' : ''}>Skills</a>
        </li>
        <li>
          <a href="#experiences" className={activeSection === 'experiences' ? 'active' : ''}>Esperienze</a>
        </li>
        <li>
          <a href="#contact" className={activeSection === 'contact' ? 'active' : ''}>Contatti</a>
        </li>
      </ul>
    </nav>
  );
}

export default NavMobile;
