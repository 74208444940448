import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    privacyChecked: false
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.privacyChecked) {
      setLoading(true);
      try {
        const data = new FormData();
        data.append('name', formData.name);
        data.append('email', formData.email);
        data.append('text', formData.message);
        data.append('acceptance', true);

        const response = await axios.post('https://www.francescatronci.it/email/send_mail.php', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.result === false) {
          alert('Form non inviato!');
        } else {
          alert('Form inviato con successo!');
        }
        console.log(response.data);
      } catch (error) {
        console.error('Errore nell\'invio del form:', error);
        alert('Si è verificato un errore durante l\'invio del form. Riprova più tardi.');
      } finally {
        setLoading(false);
      }
    } else {
      alert("Devi accettare la privacy per inviare il form");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-4">
      <div className="mb-3">
        <label htmlFor="name" className="form-label">Nome</label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="message" className="form-label">Messaggio</label>
        <textarea
          className="form-control"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="4"
          required
        ></textarea>
      </div>
      <div className="mb-3 form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="privacyChecked"
          name="privacyChecked"
          checked={formData.privacyChecked}
          onChange={handleChange}
          required
        />
        <label className="form-check-label" htmlFor="privacyChecked">
          Accetto la privacy
        </label>
      </div>
      <button type="submit" className="btn btn-primary" disabled={!formData.privacyChecked || loading}>
        {loading ? (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ) : (
          'Invia'
        )}
      </button>
    </form>
  );
};

export default ContactForm;
