import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    const welcomeSection = document.getElementById('welcome');
    if (welcomeSection) {
      welcomeSection.scrollIntoView({ behavior: 'smooth' });
      window.history.pushState(null, '', window.location.pathname); // This updates the URL without reloading the page
    }
  };

  return (
    <>
      {showButton && (
        <div onClick={scrollToTop} className="scroll-to-top">
          <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} className="Logo-mobile" alt="Logo" />
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
