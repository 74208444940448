import React from 'react';

function Card({ title, address, description }) {
    return (
        <div className='card-container'>
            <div className='card-content'>
                <h3 className='card-title'>{title}</h3>
                <p className='card-address'><em>{address}</em></p>
                <p className='card-description'>{description}</p>
            </div>
        </div>
    );
}

export default Card;