import NavMobile from './components/NavMobile';
import Navbar from './components/NavBar';
import ScrollToTopButton from './components/ScrollToTop';
import '../src/styles/style.scss';
import About from './pages/About';
import Welcome from './pages/Welcome';
import Study from './pages/Study';
import Skill from './pages/Skills';
import Experience from './pages/Experiences';
import Contact from './pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App App-container">
      <header className="App-header">
        <Navbar />
      </header>
        <ScrollToTopButton />
        <Welcome />
        <About />
        <Study />
        <Skill />
        <Experience />
        <Contact />
      <div className='App-bottom'>
        <NavMobile />
      </div>
    </div>
  );
}

export default App;
